@use "uswds-core" as *;

.service-contact {
  background-color: color("white");
  border-bottom: 1px solid color("gray-cool-10");
  font-size: font-size("sans", "2xs");
  line-height: line-height("sans", 3);
  margin-bottom: 0;
  padding: units(1.5);

  @include at-media("tablet") {
    padding: units(1.5) units(2.5);
  }

  &__copy {
    align-items: start;
    display: flex;

    p {
      margin-left: units(3);
      margin-top: 0;

      @include at-media("tablet") {
        margin-left: 0;
      }
    }

    &-icon {
      flex: none;

      img {
        margin-right: units(1);
        position: relative;
        top: 2px;
        width: units(2);
      }
    }

    h3 {
      font-size: font-size("sans", "sm");
      line-height: line-height("sans", 3);
      margin: 0;
      word-break: break-word;

      a {
        color: color("blue-warm-80");
        font-weight: font-weight("medium");
        text-decoration: none !important;

        &:hover {
          span {
            border-bottom: 1px solid color("blue-warm-80");
          }
        }
      }
    }

    .source-url {
      font-size: font-size("sans", "3xs");
      line-height: line-height("sans", 2);
      margin-left: 0;
      margin-top: 2px;
      word-break: break-all;

      a {
        color: color("base-dark");
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .summary {
      font-size: font-size("sans", "xs");
      margin: units(1.5) 0;

      @include at-media("tablet") {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .authors-list--inline {
      margin: 0;
      width: units("card-lg");
    }

    .author span {
      white-space: wrap;
      word-break: break-word;
    }
  }

  .copy-contacts {
    margin-left: units(3);

    @include at-media("tablet") {
      margin-left: 0;
    }

    &__contact {
      color: color("black");
      margin-right: units(1.5);
      margin-top: units(1);
      text-decoration: none;
      width: units(15);
      word-break: unset;

      @include at-media("tablet") {
        margin-right: units(2);
      }

      &:hover {
        span {
          border-bottom: 1px solid color("black");
        }
      }
    }
  }
}
