@use "uswds-core" as *;

// Content settings
.content {
  @include u-margin-bottom(5);
}

.main-topic .content * {
  @include u-measure(6);
}

.usa-prose {
  // Applies the arrow to all external links
  // Select all the links with href that start with "http" and do not contain ".gov"
  // See https://css-tricks.com/attribute-selectors/
  a[href^="http"]:not([href*=".gov"])::after {
    background: url("../uswds/img/external-link.svg") no-repeat 0 0;
    background-size: 100%;
    content: "";
    display: inline-block;
    height: 0.65em;
    margin-bottom: -1px;
    margin-left: 0.25rem;
    width: 0.65em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @include u-margin-bottom(0);
  }

  h3 {
    @include u-text("medium");
  }

  // HR tags
  hr {
    @include u-margin-y(3);
    clear: both;
  }

  // Inline code
  // Example: `/resources/guide-create-mobile-friendly-websites`
  code {
    @include u-padding-x(0.5);
    @include u-bg("white");
    @include u-font("mono", "xs");
    @include u-line-height("mono", 1);
    @include u-border("1px", "solid", "base-lighter");
    @include u-radius(sm);
  }

  pre {
    @include u-padding-x(3);
    @include u-padding-y(2);
    @include u-padding-bottom("205");
    @include u-padding-right(4);
    @include u-bg("base-lightest");
    @include u-border("1px", "solid", "base-lighter");
    @include u-color("indigo-warm-80");
    @include u-radius("md");
    @include u-font("mono", "2xs");
    white-space: pre-wrap;
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }

  // Quotes
  blockquote {
    @include u-margin-x(5);
    @include u-measure(6);
    @include u-margin-left(0);
    @include u-padding-left(5);
    @include u-border-left("05", "solid", "base-lighter");
  }

  table tbody {
    vertical-align: baseline;
  }
}

.usa-prose > *:first-child {
  @include u-margin-top(0);
}
