// Styles for the list of authors generally found on blog posts and event pages
// NOTE: The authors-list is included twice on the page. Once for desktop, the other for mobile.
@use "uswds-core" as *;

// authors landing override
.authors {
  .author__name {
    @include u-font("sans", "xs");
    @include u-line-height("sans", 1);
    @include u-margin-y("05");
    @include u-text("bold", "black", "no-underline");

    &:hover {
      @include u-border-bottom("2px", "accent-warm", "solid");
    }
  }
}

// Display a vertically stacked list of authors
.authors-list {
  margin-bottom: units(7);
  margin-top: 0;

  .author {
    border-top: 1px color("base") solid;
    flex-wrap: nowrap;
    margin-bottom: units("105");
    padding-top: units("105");

    &:first-child {
      border-top: 0;
    }
  }

  .author__photo-img {
    border: 1px color("indigo-30") solid;
    @include u-circle(5);
  }

  .author__details-agency {
    font-size: font-size("sans", "3xs");
    margin: 0;
  }
}

// Display as an inline list of authors
.authors-list--inline {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: units(1);
  margin-top: units("105");
  max-width: 100%;

  .author {
    align-items: center;
    border: none;
    display: flex;
    margin-bottom: units("05");
    margin-left: 0;
    margin-right: units("105");
    margin-top: units("05");
    padding: 0;
    z-index: 2;
  }

  .author__name {
    color: color("primary-darker");
    flex: 1;
    font-size: font-size("sans", "2xs");
    font-weight: font-weight("medium");
    white-space: nowrap;
  }

  .author__photo-img {
    border: 1px color("indigo-30") solid;
    margin-right: units("05");
    @include u-circle(3);
  }
}
