@use "uswds-core" as *;

.dg-guide__nav-container {
  display: none;
  flex-shrink: 0;
  margin-top: units(7);
  position: sticky;
  top: units(15);
  width: 10rem;

  @include at-media("tablet") {
    display: block;
  }

  @include at-media("desktop") {
    margin-top: units(7);
    width: 15rem;
  }

  ul.dg-guide__nav-list {
    @include u-font("sans", "xs");
    border-radius: units(2);
    overflow-x: hidden;

    li {
      font-weight: font-weight("medium");

      a {
        @include u-padding-y(2);
        @include u-text("black", "normal", "no-underline");

        &:focus {
          border: units("05") solid color("primary");
          border-radius: units(2);
          outline: 0;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .dg-current {
        background-color: color("gray-cool-4");
        font-weight: font-weight("semibold");
      }

      .usa-current {
        background-color: color("primary");
        color: color("white");

        &:hover {
          background-color: color("blue-warm-60v");
          color: color("white");
        }
      }
    }

    li.current {
      & > a {
        font-weight: font-weight("semibold");
      }
    }

    li.usa-sidenav__item {
      a {
        &:not(.usa-current) {
          color: color("gray-cool-90");
        }

        &:hover:not(.usa-current) {
          color: color("blue-warm-60v");
        }
      }
    }

    // * After first top level link, indent following subnav links to show visual hierarchy
    & > li.usa-sidenav__item:not(:first-child) a:not(.usa-button) {
      padding-left: 2rem;
    }

    // * Indent sublist links for current page to show visual hierarchy
    ul.usa-sidenav__sublist li.usa-sidenav__item a:not(.usa-button) {
      padding-left: 2.75rem;
    }

    li.usa-sidenav__item--read {
      background-color: color("gray-cool-4");
    }
  }

  .usa-sidenav {
    .usa-current::after {
      content: none;
    }
  }
}
