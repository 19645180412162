@use "uswds-core" as *;

// Table of Contents for resources/list.html:112 will be replaced with future component work

// Hiding the bullets on the TOC except for the guide pages
.toc {
  border-image: linear-gradient(to bottom, #4a50c4, #0076d6, #fff) 1;
  border-style: solid;
  margin-bottom: units(5);
  padding: units(2) units("205");

  @include u-text("indigo-80");

  h4 {
    margin: 0;
    margin-bottom: units(2);
  }

  ul {
    @include add-list-reset;
    li {
      margin: units("105") units(0);

      overflow-wrap: break-word;
      @include u-font("sans", "xs");
      @include u-line-height("sans", 3);

      &:last-child {
        margin-bottom: 0;
      }

      a {
        padding: units(0.5);
        @include u-text("normal");
        @include u-text("no-underline");
        @include u-text("indigo-70");
        @include u-font("sans", "sm");
        @include u-line-height("sans", 2);
        @include u-display("block");

        &:hover {
          @include u-shadow(3);
        }
      }
    }
  }
}
