@use "uswds-core" as *;

// nav

// Styles the desktop horizontal menu bar
.dg-guide__menu-bar {
  @include u-border-y("1px", "gray-cool-30", "solid");
  @include u-z(200);
  background-color: color("gray-10");
  height: units(10);
  position: sticky;
  top: -1px;
  white-space: nowrap;

  &-container {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &-image-container {
    flex-shrink: 0;
    margin-left: units(3);
    margin-right: units(3);

    a {
      text-decoration: none;
    }

    img {
      height: units(4);
    }
  }

  &-links {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    // Fade out overflowing items when scrolling
    // mask: linear-gradient(90deg, #0000, #000 1rem 95%, #0000);
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;

    @media (max-width: 87.5rem) {
      // padding-left: calc(50vw - 36rem);
    }
  }

  &-links::-webkit-scrollbar {
    display: none;
  }

  .dg-guide__menu-bar-link {
    border-bottom: units(0.5) solid transparent;
    color: color("ink");
    font-size: size("sans", "md");
    font-weight: font-weight("light");
    margin-right: units(5);
    text-decoration: none;

    &:hover {
      border-bottom: units(0.5) color("primary") solid;
      color: color("black");
    }

    &:visited {
      color: color("black");
    }
  }

  &.sticky {
    @include at-media("mobile-lg") {
      align-items: center;
      display: flex;
      justify-content: left;
    }
    .dg-guide__menu-bar-image-container {
      align-items: flex-end;
      align-self: center;
      display: flex;
    }
  }

  .dg-current {
    border-bottom: units(0.5) color("primary") solid;
    font-weight: font-weight("medium");
  }

  .dg-glossary__toggle {
    display: inline;

    &-icon {
      margin-left: units(1);
      // Set width explicitly for proper rendering on Safari browsers
      width: units(5);
    }
  }
}

// Styles the mobile vertical menu bar
.guide-mobile-menu {
  position: sticky;
  top: -1px;
  z-index: z-index(100);

  &__content {
    background-color: color("gray-3");
    box-shadow: 0px 12px 12px 2px rgba(0, 0, 0, 0.35);
    padding: 0;
  }

  &__heading {
    align-items: center;
    background-color: color("gray-cool-5");
    // Fixes flexbox width layout overflow
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: units(7);
  }

  &__image {
    display: flex;
    width: max-content;
  }

  &__image-icon {
    flex: 1 0 0;
    height: units(4);
    width: units(4);
  }

  &__image-container {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  &__image-dg-icon {
    margin-left: units(2);
  }

  &__image-glossary {
    margin-left: units(2);
    margin-right: units(1);
    width: auto; // override max-content;
  }

  &__image-glossary-svg {
    margin-top: units(1);
    width: auto;
  }

  &__button {
    align-items: center;
    background-color: color("blue-warm-20v");
    display: flex;
    flex: 1 0 0;
    height: 100%;
    justify-self: end;
    text-align: left;
    width: 10rem;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: color("blue-warm-20v");
    }
  }

  &__button-header {
    margin: 0;
    padding: 0;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: units(2);
  }

  .dg-guide__menu-bar-link {
    align-items: center;
    color: color("black");
    display: flex;
    font-size: font-size("sans", "md");
    font-weight: font-weight(400);
    height: units(6);
    margin: 0 units(1.5);
    text-decoration: none;

    &:hover {
      color: color("black");
    }

    &:visited {
      color: color("black");
    }

    &.dg-current {
      color: color("blue-warm-60v");
      font-weight: font-weight(700);
    }
  }
}
