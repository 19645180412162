@use "uswds-core" as *;

.quote-block {
  background-color: color("gold-5");
  border-radius: radius("sm");
  margin-bottom: units(3);
  max-width: 100%;

  blockquote {
    border-left: units(0.5) solid color("gold-30");
    color: color("gold-60");
    font-family: font-family("serif");
    font-size: font-size("serif", "md");
    margin: 0;
    padding: units(3) units(5) units(3) units(5);
    padding-right: units(3);
    text-indent: units(-2);

    @include at-media("tablet") {
      padding: units(3) units(5) units(3) units(5);
      padding-right: units(4);
    }

    &__quotation-mark {
      margin-right: units(0.5);
    }

    cite {
      display: block;
      font-family: font-family("sans");
      font-size: font-size("serif", "md");
      font-style: italic;
      line-height: line-height("serif", 2);
      margin-top: units(2);
      padding-right: units(4);
      text-align: right;
    }
  }

  // variant to display a darker background and light text
  &--dark {
    background-color: color("gray-cool-70");

    blockquote {
      border-left: units(0.5) solid color("gray-cool-20");
      color: color("white");
    }
  }
}
