@use "uswds-core" as *;

// Events

.events-list {
  .event {
    @include u-margin-bottom(5);

    h2,
    h3 {
      @include u-margin(0);
      @include u-margin-bottom("05");
      @include u-text("bold");
      @include u-font("sans", "lg");

      a {
        @include u-text("no-underline");
        @include u-text("primary-darkest");

        &:hover {
          @include u-border-bottom("2px", "primary-vivid", "solid");
        }
      }
    }

    h5 {
      @include u-margin(0);
      @include u-font("sans", "sm");
      @include u-text("medium");
      @include u-text("accent-warm-dark");

      span {
        @include u-margin-x("05");
        @include u-text("accent-warm-dark");
      }
    }

    p {
      @include u-margin-top(1);
      @include u-font("sans", "sm");
      @include u-text("light");
      @include u-measure(2);
    }
  }
}

.event {
  .datetime {
    @include u-margin-y(2);
    @include u-text("semibold");

    span {
      @include u-display("block");

      @include at-media("tablet") {
        @include u-margin-left("105");
        @include u-display("inline");
      }
    }
  }

  .host {
    @include u-text("gray-warm-60");
  }

  .stage {
    @include u-margin-bottom(5);
    @include u-bg("base-lightest");
    @include u-padding(3);

    // YouTube LIVE Chatbox
    .chat-box {
      iframe {
        width: 322px;
      }
    }
  }

  .stage-zoom {
    @include u-margin-bottom(4);
    @include u-padding(2);
    @include u-radius("sm");
    @include u-display("flex");

    p {
      @include u-margin-top(0);
      @include u-margin-bottom(2);
      @include u-maxw("full");
      @include u-font("sans", "2xs");
      @include u-line-height("sans", 3);

      @include at-media("tablet") {
        @include u-font("sans", "xs");
        @include u-line-height("sans", 3);
      }

      &:last-child {
        @include u-margin-bottom(0);
      }
    }

    .icon {
      @include u-font("sans", "md");
      @include u-margin-top("05");
      @include u-margin-right(1);

      @include at-media("tablet") {
        @include u-margin-right("105");
        @include u-font("sans", "lg");
      }

      p {
        @include u-text("primary-darker");
        @include u-text("center");
        @include u-circle(5);

        @include at-media("tablet") {
          @include u-circle(6);
        }

        @include u-border("2px", "solid", "primary-darker");
        @include u-display("flex");
        @include u-flex("align-center");
        @include u-flex("justify-center");
      }
    }
  }

  // Captions URL
  .captions {
    @include u-padding-y(1);
    @include u-padding-x(0);
    @include u-font("sans", "xs");
    @include u-text("no-underline");
    @include u-text("primary-darker");

    &:hover {
      span {
        @include u-border-bottom("2px", "primary-vivid", "solid");
      }
    }
  }

  .event-tools {
    @include u-padding(1);
    @include u-bg("gray-warm-2");

    // YouTube LIVE
    // styles for a link that takes people directly to YouTube
    .youtube {
      @include u-padding-y(1);
      @include u-padding-x(1);
      @include u-font("sans", "xs");
      @include u-text("no-underline");
      @include u-radius("md");
      @include u-text("red-50");

      &:hover {
        span {
          border-bottom: 2px #d83933 solid;
        }
      }
    }

    .captions {
      @include u-padding-x(1);
    }

    // YouTube LIVE Comments box notice
    .notice {
      @include u-padding-x(1);
      @include u-font("sans", "3xs");
    }
  }
}
