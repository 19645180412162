@use "uswds-core" as *;

.dg-redirect {
  background-color: color("primary-dark");
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding: units(2);
    width: 100%;

    @include at-media("mobile-lg") {
      padding: units(6);
    }
  }

  &__header {
    color: white;
    display: flex;
    margin-bottom: 0;

    h1 {
      font-size: font-size("sans", "xl");
      font-weight: font-weight(400);
    }
  }

  &__body {
    align-items: center;
    background-color: white;
    border-radius: units(1.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20rem;
    padding: units(2);

    @include at-media("mobile-lg") {
      padding: units(2);
    }

    h2 {
      color: color("primary-dark");
      font-weight: font-weight(400);
    }
  }

  &__body-links li {
    margin-top: units(1);
  }

  &__body-buttons {
    padding-top: units(2);

    a:first-child {
      margin-bottom: units(2);
    }
  }

  &__touchpoints {
    display: flex;
    justify-content: center;
  }
}
