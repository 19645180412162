@use "uswds-core" as *;

.card-event {
  grid-template-areas:
    "c-main"
    "c-aside";

  @include at-media("tablet") {
    column-gap: units("205"); // Gives border consistent spacing on both sides
    grid-template-areas: "c-main c-aside";
    grid-template-columns: 2fr 1fr;
  }

  &__main {
    grid-area: c-main;
  }

  &__aside {
    @include u-width("auto");

    grid-area: c-aside;

    @include at-media("tablet") {
      @include u-padding-left(2);
      @include u-border-left("1px", "indigo-warm-80", "solid");
    }
  }

  &__title {
    // refactor into _card.scss to update all card components from h3 to h2
    @include u-font("sans", "lg");
    @include u-margin(0);
    @include u-text("bold");

    a {
      @include u-text("primary-darker");
      @include u-text("no-underline");

      &::after {
        @include u-position("absolute");
        @include u-top(0);
        @include u-left(0);
        @include u-right(0);
        @include u-bottom(0);

        content: "";
      }
    }
  }

  &__deck {
    @include u-margin-top(1);
    @include u-line-height("sans", 3);
    @include u-font("sans", "md");
    @include u-text("normal");
  }

  .authors-list {
    @include u-display("none");

    @include at-media("tablet") {
      @include u-display("flex");
      @include u-padding-bottom(2);
    }
  }

  &__kicker {
    @include u-display("none");
    @include u-margin(0);
    @include u-margin-bottom("05");
    @include u-font("sans", "2xs");
    @include u-text("semibold");
    @include u-text("uppercase");
    @include u-color("primary-vivid");

    @include at-media("tablet") {
      display: block;
    }
  }

  &__host {
    @include u-font("sans", "md");
    @include u-margin-y(1.5);
    @include u-color("gray-cool-60");
    @include u-line-height("sans", 1);
  }

  &__date-label {
    @include u-padding-left(0);
    @include u-text("gray-cool-50");
    @include u-text("normal");

    font-weight: 600;

    width: 80%;

    @include u-font("sans", "sm");
    @include u-border(0); // reset inherited styles from card-event
    @include u-border-top("2px", "gray-cool-50", "solid");
    @include u-radius("sm");
    @include u-margin-x("auto");

    &--mobile {
      @include u-display("block");

      @include at-media("tablet") {
        @include u-display("none");
      }
    }
  }

  &__datetime {
    @include u-display("none");

    @include at-media("tablet") {
      @include u-display("flex");
      @include u-flex("align-center");
      @include u-flex-direction("column");
    }

    &--mobile {
      @include u-display("inline");

      font-size: 1rem;
      font-weight: inherit;

      @include at-media("tablet") {
        @include u-display("none");
      }

      .card-event__datetime-month,
      .card-event__datetime-day {
        @include u-font("sans", "sm");
        font-size: 1rem;

        font-weight: 400;
        text-transform: capitalize;
      }
    }

    &-month {
      @include at-media("tablet") {
        @include u-padding-right(0);
        @include u-font("sans", "xl");
        @include u-text("bold");
        @include u-text("uppercase");
        @include u-text(800);

        justify-self: center;
      }
    }

    &-day {
      @include u-font("sans", "2xl");
      @include u-line-height("sans", 1);
      @include u-margin-bottom(0);
      @include u-text("thin");

      justify-self: start;

      @include at-media("tablet") {
        @include u-padding-bottom(1.5);
        @include u-margin-top(-1);

        font-size: 5rem;
      }
    }

    &-comma {
      @include u-display("none");
    }

    &-year--mobile {
      // add styles here to hide on desktop, show on mobile
      @include u-display("inline");

      @include at-media("tablet") {
        @include u-display("none");
      }
    }
  }

  &__watch-label {
    @include u-margin-top(1);
    @include u-margin-bottom(0);
    @include u-font("sans", "2xs");
    @include u-text("semibold");
    @include u-text("uppercase");
    @include u-color("indigo-warm-60v");

    @include at-media("tablet") {
      @include u-margin-y(1);
    }
  }

  // @TODO: Refactor into a button variant
  &__cta {
    @include u-display("block");
    @include u-border("2px", "primary-vivid", "solid");
    @include u-radius("sm");
    @include u-bg("white");
    @include u-padding(0);
    @include u-font("sans", "sm");
    @include u-text("primary-vivid");
    @include u-text("center");
    @include u-text("no-underline");

    transition: background-color 0.25s, color 0.25s;
    width: 100%;

    @include at-media("tablet") {
      @include u-display("block");
      margin-left: auto;

      margin-right: auto;
      width: 80%;
    }
  }

  &:hover {
    .card-event__cta {
      @include u-bg("primary-vivid");
      @include u-text("white");
    }
  }
}

// ==========================================================================
// Variants
// ==========================================================================

// Past events
// ==========================================================================
.card-event--past {
  .card-event__date-label {
    @include u-display("none");

    @include at-media("tablet") {
      @include u-display("flex");

      justify-content: center;
    }
  }

  .card-event__date {
    @include u-text("gray-cool-50");
    @include u-text("normal");
    @include u-font("sans", "sm");

    @include at-media-max("tablet") {
      @include u-display("flex");
      @include u-flex-direction("row");
      @include u-padding-left(0);
      @include u-border-top("2px", "gray-cool-50", "solid");
      @include u-radius("sm");

      transition: color 0.25s;
    }
  }

  .card-event__datetime {
    @include u-display("flex");

    @include at-media-max("tablet") {
      @include u-display("inline");
    }
  }

  .card-event__datetime-month,
  .card-event__datetime-day {
    @include at-media-max("tablet") {
      font-size: 1rem;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  .card-event__datetime-comma {
    @include u-display("inline");

    @include at-media("tablet") {
      @include u-display("none");
    }
  }

  &:hover {
    .card-event__date {
      @include u-text("gray-cool-70");
    }

    .card-event__date-label {
      @include u-border-top("2px", "gray-cool-70", "solid");
      @include u-text("gray-cool-70");
    }
  }
}
