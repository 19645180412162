@use "uswds-core" as *;

.stream {
  h2 {
    @include u-font("sans", "xl");
    @include u-text("bold");
    @include u-text("indigo-warm-80");

    @include at-media("tablet-lg") {
      @include u-font("sans", "lg");
    }
  }

  .card:first-child {
    @include u-margin-top(0);
  }
}

.pagination {
  @include u-margin-top(3);
  @include u-margin-bottom(5);
  @include u-padding(0);
  align-items: center;
  column-gap: units("2px");

  display: flex;
  flex-flow: row wrap;

  li {
    @include add-list-reset;
    @include u-display("inline-block");
    @include u-text("center");

    a {
      @include u-text("blue-warm-70v");
      @include u-text("no-underline");
      @include u-circle(5);
      @include u-display("flex");
      @include u-flex("column");
      @include u-flex("justify-center");
      @include u-flex("align-center");

      &:hover {
        @include u-bg("blue-10");
      }
    }
  }

  li.active a {
    @include u-text("white");
    @include u-bg("blue-warm-70v");
  }
}

.dg-stream {
  background-color: color("blue-warm-5");
}
