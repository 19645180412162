@use "uswds-core" as *;

// The styles for .list-topics
// (those topics that can be found on the article and event pages).

.list-topics {
  @include u-margin-y(2);
  @include u-font("sans", "2xs");
  @include u-text("normal");

  a {
    @include u-margin-y("2px");
    @include u-margin-left("2px");
    @include u-padding-y("2px");
    @include u-padding-x(1);
    @include u-display("inline-block");
    @include u-text("no-underline");
    @include u-radius("pill");
    @include u-bg("indigo-warm-10");
    @include u-text("indigo-warm-80");
    @include u-line-height("sans", 2);
    @include u-position("relative");

    transition: background-color 0.2s, color 0.2s;
    z-index: 2;
  }

  a:hover {
    @include u-bg("indigo-warm-20");
    @include u-text("indigo-warm-80");
  }
}
