@use "uswds-core" as *;

.dg-note {
  background-color: color("blue-5");
  border-left-color: color("blue-20");
  border-left-style: solid;
  border-left-width: 5px;
  color: color("blue-70");
  font-size: size("ui", "md");
  font-weight: font-weight("light");
  padding: units(3);

  &__heading {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: family("sans");
    font-size: units(2.5);
    font-weight: font-weight("light");
    margin-bottom: units(1.5) !important;
    margin-top: 0;
  }

  &__icon {
    margin-right: units(1);
  }

  a {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: color("blue-70");
    text-decoration: none;

    &:hover {
      @include u-text("no-underline");
      color: color("blue-70");
    }
  }

  strong {
    font-weight: font-weight("medium");
  }

  p:last-child {
    margin-bottom: 0;
  }

  blockquote {
    border: 0;
    padding-left: 0;
  }

  ol,
  ul {
    li {
      margin-bottom: units(0.5);
    }
  }

  // For notes within list items
  li .note {
    @include u-padding-y("05");
    @include u-padding-x(1);
  }
  // Activities  — color settings
  &--activity {
    background-color: color("orange-warm-5");
    border-left-color: color("orange-warm-30");
    border-left-style: solid;
    border-left-width: 5px;
    color: color("orange-warm-60");

    a {
      border-bottom-color: color("orange-warm-60");
      border-bottom-style: solid;
      border-bottom-width: 1px;
      color: color("orange-warm-60");

      &:hover {
        color: color("orange-warm-80");
      }
    }
  }
  // Alert styles
  &--alert {
    background-color: color("red-5");
    border-left-color: color("red-40v");
    border-left-style: solid;
    border-left-width: 5px;
    color: color("red-60v");

    a {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      color: color("red-60v");

      &:hover {
        color: color("red-80v");
      }
    }
  }
  // Comment — color settings
  &--comment {
    background-color: color("blue-cool-10");
    border-left-color: color("blue-cool-50");
    border-left-style: solid;
    border-left-width: 5px;
    color: color("blue-cool-80");

    a {
      border-bottom-style: solid;
      border-bottom-width: 1px;

      &:hover {
        color: color("blue-cool-80");
      }
    }

    blockquote {
      font-family: family("serif");
      font-size: size("ui", "lg");
    }
  }
  &--disclaimer {
    background-color: color("gray-cool-3");
    border-left-color: color("gray-cool-20");
    border-left-style: solid;
    border-left-width: 5px;
    color: color("gray-warm-90");
  }
}
