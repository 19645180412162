@use "uswds-core" as *;

.highlight {
  code {
    background: none;
    border: none;
  }

  &-text {
    @include u-padding-y("1px");
    @include u-padding-x("05");
    @include u-bg("yellow-10v");
  }
}
