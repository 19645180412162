@use "uswds-core" as *;

.dg-more-link {
  &:hover {
    text-decoration-color: color("accent-warm");
  }
}

a.btn {
  transition: background-color 0.2s, border 0.2s, border-color 0.2s;

  i {
    @include u-margin-left("05");
  }

  &:hover {
    @include u-bg("indigo-70");

    span {
      @include u-border-bottom("2px", "white", "solid");
    }
  }
  @include u-padding-y("105");
  @include u-padding-x(2);
  @include u-display("inline-block");
  @include u-font("sans", "sm");
  @include u-color("white");
  @include u-text("medium");
  @include u-text("center");
  @include u-text("no-underline");
  @include u-radius("md");
  @include u-bg("indigo-60v");

  @include at-media("tablet") {
    @include u-padding-y("105");
    @include u-padding-x(3);
    @include u-font("sans", "md");
  }
}

a.btn-home {
  @include u-border("2px");
  background-color: color("transparent");
  margin: units(2);

  &:first-of-type {
    margin-bottom: 0;
  }

  @include at-media("tablet-lg") {
    @include u-margin-x(0);
  }

  &:hover {
    background-color: color("white");
    border-color: color("white");
    color: color("black");
    span {
      @include u-border-bottom("2px", "black", "solid");
    }
  }
}

a.btn-sm {
  @include u-padding-y("05");
  @include u-padding-x(1);
  @include u-font("sans", "2xs");
  @include u-bg("white");
  @include u-text("primary");
  @include u-text("medium");
  @include u-border("2px", "primary", "solid");

  @include at-media("tablet") {
    @include u-padding-y("05");
    @include u-padding-x(1);
    @include u-font("sans", "2xs");
  }

  &:hover {
    @include u-bg("white");
    @include u-text("indigo-70");
    @include u-border("2px", "indigo-70", "solid");
  }
}

.join-buttons {
  @include u-margin-y(1);
  @include u-text("center");
  @include u-display("flex");
  @include u-flex("wrap");
  @include u-flex("row");
  @include u-flex("justify-center");
  @include u-flex("align-center");

  @include at-media("tablet") {
    @include u-margin-y(3);
  }

  a {
    @include u-margin-x("05");
    @include u-margin-bottom(1);
    @include u-padding-y("05");
    @include u-padding-x(1);
    @include u-text("no-underline");
    @include u-text("medium");
    @include u-text("primary-vivid");
    @include u-radius("md");
    @include u-display("inline-block");
    @include u-font("sans", "xs");

    transition: background-color 0.5s, color 0.5s;

    @include at-media("tablet") {
      @include u-padding-y("05");
      @include u-padding-x("105");
      @include u-font("sans", "sm");
      @include u-border("1px", "primary-vivid", "solid");
    }

    &:hover {
      @include u-bg("primary-vivid");
      @include u-text("white");
    }

    img {
      @include u-position("relative");
      @include u-top("05");
      @include u-width(3);

      @include at-media("tablet") {
        @include u-top("2px");
      }
    }
  }
}

.dg-register {
  display: flex;
  margin-bottom: units(3);
}

a.dg-register__button {
  border-radius: 0;
  font-family: font-family("sans");
  font-size: font-size("sans", "md");
  font-weight: font-weight(200);
  padding-left: units(4);
  padding-right: units(4);
  text-transform: uppercase;
  width: auto;
}

.actions-stacked {
  @include u-display("block");

  .btn {
    @include u-display("block");
    @include u-padding-x(1);
  }

  .addtocalendar {
    @include u-display("block");

    a {
      @include u-display("block");
      @include u-padding-x(1);
    }
  }
}
