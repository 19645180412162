@use "uswds-core" as *;

.image {
  margin-bottom: units(4);
  margin-top: units(2);

  // Captions
  p {
    color: color("gray-cool-60");
    font-family: family("sans");
    font-size: size("sans", "2xs");
    font-weight: font-weight("light");
    line-height: line-height("sans", 3);
    margin-top: 1px;
    max-width: 100%;
    padding-left: units(2px);
    padding-right: units(2px);

    span {
      font-style: italic;
    }
  }
}

.image-right {
  float: right;
  margin-bottom: units(3);
  margin-left: units(3);
  margin-top: units(1);
  width: units(15);

  @include at-media("tablet") {
    width: units("card");
  }
}

.image-right-legacy {
  @include at-media("tablet") {
    width: units("card-lg");
  }
}

.image-left {
  float: left;
  margin-bottom: units(5);
  margin-right: units(2);
  margin-top: units(5);
  width: units("card-lg");
}

.image-center {
  display: block;
  text-align: center;

  img {
    max-width: 100%;
    width: units("card-lg");
  }
}

.image-inline {
  display: inline-block;
  margin: units(1);
  text-align: center;
  vertical-align: middle;

  img {
    display: inline-block;
    height: auto;
    max-width: 100%;
  }
}

img[alt] {
  font-style: italic;
}
